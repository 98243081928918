interface propTypes {
  fill?: string;
  className?: string;
}

const ArtShape = ({ fill = '#E1A16A', className }: propTypes) => (
  <svg width="200" height="70" viewBox="0 0 198 71" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M54.958 70.4035L49.4152 54.1945H23.4414L17.9141 70.4035H1L27.7998 1H45.1035L71.7786 70.3932L54.958 70.4035ZM28.0387 40.5079H44.6204L36.3088 16.254L28.0387 40.5079Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path d="M117.794 70.4035L100.853 46.0095H97.2483V70.4035H81.3887V1H110.713C127.414 1 134.864 12.5223 134.864 24.0497C134.864 33.9111 129.456 42.5373 117.679 45.1791L136.308 70.3932L117.794 70.4035ZM97.2483 14.9357V33.5426H108.157C115.607 33.5426 118.973 28.9804 118.973 24.4182C118.973 19.856 115.487 14.9357 108.76 14.9357H97.2483Z" stroke={fill} strokeMiterlimit="10" />
    <path d="M197.102 1V14.9253H177.757V70.4035H161.892V14.9357H142.547V1H197.102Z" stroke={fill} strokeMiterlimit="10" />
  </svg>
);

export default ArtShape;
