import classNames from 'classnames';

import Image_1 from '@assets/images/About_1_1.png';
import Image_2 from '@assets/images/About_1_2.png';
import Image_3 from '@assets/images/About_1_3.png';
import Image_4 from '@assets/images/About_2.png';

import styles from './style.module.scss';
import animations from '@styles/animations.module.scss';

interface propTypes {
  inView: boolean;
}

const SecondSubSection = ({ inView }: propTypes) => (
  <div className={classNames(styles.second_sub_section)}>
    <div className={classNames(styles.second_sub_section__image_wrapper, inView && animations.from_bottom_to_top)}>
      <div className={classNames(styles.second_sub_section__image_box, styles.image_box_1)}>
        <span>Abhishek Joshi</span>
        <img alt="about" src={Image_1} />
      </div>
      <div className={classNames(styles.second_sub_section__image_box, styles.image_box_2)}>
        <img alt="about" src={Image_2} />
      </div>
      <div className={classNames(styles.second_sub_section__image_box, styles.image_box_3)}>
        <img alt="about" src={Image_3} />
        <span>Erli Gruenzweil</span>
      </div>
    </div>
    <div className={styles.second_sub_section__content_wrapper}>
      <div className={classNames(
        styles.second_sub_section__image_box, styles.image_box_4, inView && animations.from_bottom_to_top,
      )}
      >
        <span>Victor Cord’homme</span>
        <img alt="about" src={Image_4} className={styles.image_4} />
      </div>
      <div className={styles.second_sub_section__content}>
        {/* shown only mobile version */}
        <div className={styles.second_sub_section__mobile_image_wrapper}>
          <div className={classNames(styles.second_sub_section__image_box, styles.image_box_4)}>
            <span>Victor Cord’homme</span>
            <img alt="about" src={Image_4} className={styles.image_4} />
          </div>
          <div className={classNames(styles.second_sub_section__image_box, styles.image_box_3)}>
            <span>Erli Gruenzweil</span>
            <img alt="about" src={Image_3} />
          </div>
        </div>
        <div className={classNames(styles.second_sub_section__text_box, inView && animations.from_right_to_left)}>
          <p>
            Art in Space creates and manages the international association
            of artists and galleries IMAA network (Immersive Multisensory ART Association).
            It defends a new creative experience form, encouraging the spread of
            multisensory immersive art, art of senses, through market places
            and art experiences (showcase, cultural mediations).
          </p>
          <p>
            Art in Space is supporting artists with creative spaces
            (galleries and ateliers) opening on the over the world. A real marketplace
            where to trade the art piece. The creation of a platform to meet
            and find professionals to create digital art and more.
          </p>
          <h3 className={styles.second_sub_section__sub_title}>
            We are Art in Space and we support
            a new creative experience form.
          </h3>
        </div>
      </div>
    </div>
  </div>
);

export default SecondSubSection;
