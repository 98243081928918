interface propTypes {
  fill?: string;
  className?: string;
}

const Room = ({ fill = '#E1A16A', className }: propTypes) => (
  <svg width="260" height="60" viewBox="0 0 260 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M30.8335 58.6373L16.9427 38.6705H13.9869V58.6373H1V1.76807H25.0206C38.7036 1.76807 44.8074 11.2091 44.8074 20.6553C44.8074 28.7209 40.3763 35.8003 30.7296 37.9646L45.9866 58.6217L30.8335 58.6373ZM13.9972 13.1865V28.4354H22.9582C29.062 28.4354 31.8153 24.6984 31.8153 20.9563C31.8153 17.2141 28.9634 13.171 23.4517 13.171L13.9972 13.1865Z" stroke={fill} strokeMiterlimit="10" />
    <path d="M53.6699 30.2105C53.6699 14.1779 66.9581 1 83.7995 1C100.641 1 113.929 14.1779 113.929 30.2105C113.929 46.2431 100.636 59.3224 83.7995 59.3224C66.9633 59.3224 53.6699 46.2431 53.6699 30.2105ZM100.942 30.2105C100.942 20.7695 93.8566 13.2904 83.7995 13.2904C73.6593 13.2904 66.7659 20.7695 66.7659 30.2105C66.7659 39.6515 73.6593 47.1254 83.7995 47.1254C93.8254 47.1254 100.921 39.6567 100.921 30.2105H100.942Z" stroke={fill} strokeMiterlimit="10" />
    <path d="M122.282 30.2105C122.282 14.1779 135.57 1 152.412 1C169.253 1 182.542 14.1779 182.542 30.2105C182.542 46.2431 169.248 59.3224 152.412 59.3224C135.576 59.3224 122.282 46.2431 122.282 30.2105ZM169.555 30.2105C169.555 20.7695 162.469 13.2904 152.412 13.2904C142.272 13.2904 135.378 20.7695 135.378 30.2105C135.378 39.6515 142.272 47.1254 152.412 47.1254C162.448 47.1254 169.534 39.6567 169.534 30.2105H169.555Z" stroke={fill} strokeMiterlimit="10" />
    <path d="M262.562 58.6373H249.274L245.237 26.2763L231.944 52.0457H221.902L208.515 26.2763L204.474 58.6373H191.087L198.474 1.76807H209.383L226.905 37.2743L244.349 1.76807H255.383L262.562 58.6373Z" stroke={fill} strokeMiterlimit="10" />
  </svg>
);

export default Room;
