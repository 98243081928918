import classNames from 'classnames';

import Image_1 from '@assets/images/About_1_1.png';

import styles from './style.module.scss';
import animations from '@styles/animations.module.scss';

interface propTypes {
  inView: boolean;
}

const FirstSubSection = ({ inView }: propTypes) => (
  <div className={classNames(styles.first_sub_section, inView && animations.from_left_to_right)}>
    {/* shown only mobile version */}
    <div className={styles.first_sub_section__mobile_image_box}>
      <span>Abhishek Joshi</span>
      <img alt="about" src={Image_1} />
    </div>
    <div>
      <h3 className={styles.first_sub_section__title}>
        about art in space
      </h3>
      <p>
        Art in Space is a new paradigm on Art and its expressions
        with a unic value proposal combining technology,
        galleries framework, marketplace & digital tools.
      </p>
      <p>
        Behind this, Art in Space aims at pioneering the art of senses
        allowing artists and audiences to enter a new world of sensations.
        Another parallel reality.
      </p>
      <p>
        A path to extend art and allow artists to share resources & vision,
        moments. With a social network exploring serendipity & supporting artists to promote their art worldwide.
        Inclusive with dedicated interactions and cultural
        mediation events with extended circles (art, education, curators…).
      </p>
      <h3 className={styles.first_sub_section__sub_title}>
        Radiant to make the experience
        accessible to everyone.
      </h3>
    </div>
  </div>
);

export default FirstSubSection;
