export const rooms: IRoom[] = [
  {
    title: 'ART IN SPACE 1 NO-PROFIT',
    description:
      'Research center, creative studio and non-profit gallery. Hence, the origin of the Art in Space project born in 2010. The company is dedicated to the research and selection of artists and galleries.',
    location: 'Corso Cavour n. 75, Fermo, Italy',
    photos: [
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/low/1.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/high/1.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/low/2.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/high/2.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/low/3.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/high/3.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/low/4.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/high/5.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/low/5.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/high/5.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/low/6.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/fermo/high/6.png',
      },
    ],
  },
  {
    title: 'ART IN SPACE 2 DUBAI',
    description:
      'Art in Space Dubai provides a digital space for artists and the surrounding community, located in the heart of Dubai`s Downtown main Boulevard. An active and dynamic art gallery and art-lab',
    location: 'Boulevard Crescent retail 1, Sheikh Mohammed bin Rashid Blvd, Downtown Dubai',
    photos: [
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/low/1.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/high/1.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/low/2.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/high/2.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/low/3.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/high/3.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/low/4.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/high/5.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/low/5.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/high/5.png',
      },
      {
        thumb: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/low/6.png',
        full: 'https://stageartinspace.s3.me-central-1.amazonaws.com/rooms/dubai/high/6.png',
      },
    ],
  },
];

interface IRoom {
  title: string;
  description: string;
  location: string;
  photos: {
    thumb: string;
    full: string;
  }[];
}
