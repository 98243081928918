import { forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { useView } from '@hooks';
import { SwipeDown, MainLogo } from '@components/icons';
import Video from '../../Video';

import VideoSrc from '@assets/videos/DISCOVER-BACK-16-9.mp4';
import defaultScreen from '@assets/images/background_screen.png';

import styles from './style.module.scss';
import animations from '@styles/animations.module.scss';

interface propTypes {
  id?: number;
  selectedView: Number | null;
}

const DiscoverSection = forwardRef<HTMLDivElement, propTypes>(({
  id,
  selectedView,
}, forwardedRef) => {
  const {
    ref,
    inView,
  } = useInView({});
  const { changeView } = useView();

  return (
    <section ref={selectedView === id ? forwardedRef : null}>
      <div className={styles.discover_section} ref={ref}>
        <div className={styles.discover_section__wrapper}>
          <MainLogo fill="white" />
          <h1 className={classNames(styles.discover_section__title, inView && animations.from_bottom_to_top)}>
            We are Art in Space and
            we defend a new creative
            experience form.
          </h1>
          <button
            className={classNames(styles.discover_section__swiper, inView && animations.from_bottom_to_top)}
            onClick={() => changeView(0)}
          >
            <span>
              Discover
            </span>
            <SwipeDown />
          </button>
        </div>
        <Video
          src={VideoSrc}
          className={styles.discover_section__background}
          autoPlay
          backgroundScreen={defaultScreen}
        />
      </div>
    </section>
  );
});

export default DiscoverSection;
