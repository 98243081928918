import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useView } from '@hooks';
import { Header, Footer } from '@components/main';
import Item from './Item';
import ArtRoomBack from '@assets/images/artroom-img-back.png';
import { rooms } from '@constants/rooms';

import styles from './style.module.scss';

const ArtRoom = () => {
  const navigate = useNavigate();
  const { changeView } = useView();

  const onChangeView = useCallback(
    (id: number | null): void => {
      if (id !== null) {
        changeView(id);
        navigate('/');
      }
    },
    [changeView, navigate]
  );

  return (
    <>
      <Header changeView={onChangeView} />
      <section className={styles.room}>
        <div className={styles.room__block}>
          <div className={styles.room__block_wrap}>
            <img src={ArtRoomBack} className={styles.room__block_wrap_img} alt='' />
          </div>
          <div className={styles.room__block_info}>
            <h1 className={styles.room__block_info_title}>ART IN SPACE ROOMS</h1>
            <p className={styles.room__block_info_text}>List of Art in Space rooms around the world</p>
          </div>
        </div>
        {rooms.map((room, index) => (
          <Item key={index} {...room} />
        ))}
      </section>
      <Footer />
    </>
  );
};

export default ArtRoom;
