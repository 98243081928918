import { ReactNode, useMemo, useState } from 'react';
import { ViewContext } from '@contexts/ViewContext';

interface propTypes {
  children?: ReactNode;
}

const ViewProvider = ({ children }: propTypes) => {
  const [view, setView] = useState<null | number>(null);

  const contextValue = useMemo(
    () => ({
      view,
      changeView: (id: number | null) => setView(id),
    }),
    [view, setView],
  );

  return (
    // Providing data to our app
    <ViewContext.Provider value={contextValue}>
      {children}
    </ViewContext.Provider>
  );
};


export default ViewProvider;
