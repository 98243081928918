import { forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

import Img_1 from '@assets/images/Programming_1.png';
import Img_2 from '@assets/images/Programming_2.png';
import Img_3 from '@assets/images/Programming_3.png';

import Shape_1 from '@assets/icons/circle_2.svg';
import Shape_2 from '@assets/icons/circle_3.svg';
import RectShape from '@assets/images/About_1_2.png';

import styles from './style.module.scss';
import animations from '@styles/animations.module.scss';

interface propTypes {
  id: number;
  selectedView: Number | null;
}

const ProgrammingSection = forwardRef<HTMLDivElement, propTypes>(({
  id,
  selectedView
}, forwardedRef) => {
  const {
    ref,
    inView
  } = useInView({});

  return (
    <section ref={id === selectedView ? forwardedRef : null} className={styles.programming}>
      <h2 className={classNames(styles.programming_section__name, inView && animations.from_bottom_to_top)}>
        Programming
      </h2>
      <div ref={ref} className={styles.programming__content_wrapper}>
        <div className={styles.container}>
          <div className={styles.programming__block}>
            <div className={classNames(styles.programming__block__content, inView && animations.from_left_to_right)}>
              <h2 className={styles.content__title}>
                art performances
              </h2>
              <p className={styles.content__description}>
                Many exciting events and exhibitions of our Artists will involve you emotionally and spiritually.
              </p>
              <span className={styles.content__info}>
                Soon we will present the new programming and new NFTs fine art collections.
              </span>
            </div>
            <div className={classNames(styles.programming__block__image_box, inView && animations.from_bottom_to_top)}>
              <div className={styles.first_box}>
                <img
                  className={styles.circle_shape}
                  alt="image"
                  src={RectShape}
                />
                <img
                  className={styles.img_1}
                  alt="image"
                  src={Img_1}
                />
                <img
                  className={styles.img_2}
                  alt="image"
                  src={Img_2}
                />
              </div>
              <div className={styles.second_box}>
                <img
                  className={styles.img_3}
                  alt="image"
                  src={Img_3}
                />
                <img
                  className={styles.rect_shape}
                  alt="image"
                  src={RectShape}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.programming_section__animated_back}>
          <img
            src={Shape_1}
            alt="shape"
            className={classNames(
              styles.programming_section__shape_1, inView && (animations.from_bottom_to_top, animations.shapes),
            )}
          />
          <img
            src={Shape_2}
            alt="shape"
            className={classNames(
              styles.programming_section__shape_2, inView && (animations.from_bottom_to_top, animations.shapes),
            )}
          />
        </div>
      </div>
    </section>
  );
});

export default ProgrammingSection;
