import { createContext } from 'react';

interface contextTypes {
  view: null | number;
  changeView: (id: number | null) => void;
}

// creating VIEW context/data for app
export const ViewContext = createContext<contextTypes>({
  view: null,
  changeView: () => {},
});
