import { forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import FirstSubSection from './FirstSubSection/FirstSubSection';
import SecondSubSection from './SecondSubSection/SecondSubSection';
import { ArtShape, InShape, SpaceShape } from '../../../../../../icons';

import Circle1 from '@assets/icons/circle_1.svg';
import Circle2 from '@assets/icons/circle_2.svg';
import Circle3 from '@assets/icons/circle_3.svg';

import styles from './style.module.scss';
import animations from '@styles/animations.module.scss';

interface propTypes {
  id: number;
  selectedView: Number | null;
}

const AboutSection = forwardRef<HTMLDivElement, propTypes>(({ id, selectedView }, forwardedRef) => {
  const { ref, inView } = useInView({});

  return (
    <section ref={selectedView === id ? forwardedRef : null}>
      <div ref={ref} className={styles.about_section}>
        <h2 className={classNames(styles.about_section__name, inView && animations.from_bottom_to_top)}>
          About
        </h2>
        <div className={styles.about_section__wrapper}>
          <FirstSubSection inView={inView} />
          <SecondSubSection inView={inView} />
        </div>
        <div className={styles.about_section__animated_back}>
          <ArtShape
            className={classNames(
              styles.about_section__shape_art, inView && (animations.from_bottom_to_top, animations.from_bottom_to_top),
            )}
          />
          <InShape
            className={classNames(
              styles.about_section__shape_in, inView && (animations.from_bottom_to_top, animations.from_bottom_to_top),
            )}
          />
          <SpaceShape
            className={classNames(
              styles.about_section__shape_space,
              inView && (animations.from_bottom_to_top, animations.from_bottom_to_top),
            )}
          />
          <img
            src={Circle1}
            alt="shape"
            className={classNames(
              styles.about_section__shape_1, inView && (animations.from_bottom_to_top, animations.shapes),
            )}
          />
          <img
            src={Circle2}
            alt="shape"
            className={classNames(
              styles.about_section__shape_2, inView && (animations.from_bottom_to_top, animations.shapes),
            )}
          />
          <img
            src={Circle3}
            alt="shape"
            className={classNames(
              styles.about_section__shape_3, inView && (animations.from_bottom_to_top, animations.shapes),
            )}
          />
        </div>
      </div>
    </section>
  );
});

export default AboutSection;
