import { Fragment } from 'react';
import Routes from './routes/Routes';

const App = () => (
  <Fragment>
    <Routes />
  </Fragment>
);

export default App;
