import { Link } from 'react-router-dom';
import { MainLogo } from '@components/icons';

import InstagramIcon from '@assets/icons/instagram-icon.svg';
import EmailIcon from '@assets/icons/email-icon.svg';
import styles from './style.module.scss';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footer__content}>
      <div className={styles.footer__content_left}>
        <MainLogo />
        <Link to="/faq" className={styles.footer__content_link}>FAQ</Link>
        {/* <Link to="/terms" className={styles.footer__content_link}>Terms and conditions</Link> */}
        <div className={styles.footer__content_bottom}>
          <p className={styles.footer__content_text}>
            (c) Art In Space 2022. All rights are reserved
          </p>
          <Link to="/privacy" className={styles.footer__content_link}>Privacy Policy</Link>
          <Link to="/terms" className={styles.footer__content_link}>Terms and Conditions</Link>
        </div>
      </div>
      <div className={styles.footer__content_right}>
        <p className={styles.footer__content_title}>
          contact us:
        </p>
        <div className={styles.footer__content_info}>
          <p className={styles.footer__content_address}>
            Boulevard Crescent retail 1 - Sheikh Mohammed bin Rashid Blvd - Downtown Dubai - Dubai
          </p>
          <a href="tel:+97144580853" className={styles.footer__content_phone}>
            +971 4 4580853
          </a>
          <div className={styles.footer__social}>
            <div className={styles.footer__social_item}>
              <a href="https://www.instagram.com/artinspace360/">
                <img alt="Instagram" src={InstagramIcon} className={styles.footer__social_icon} />
              </a>
              <span className={styles.footer__social_text}>
                artinspace360
              </span>
            </div>
            <div className={styles.footer__social_item}>
              <a href="mailto: info@artinspace.art">
                <img alt="Email" src={EmailIcon} className={styles.footer__social_icon} />
              </a>
              <span className={styles.footer__social_text}>
                info@artinspace.art
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
