import { Fragment, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useView } from '@hooks';
import { Header, Footer } from '@components/main';

import NotFoundImg from '@assets/icons/not-found-img.svg';
import styles from './style.module.scss';

const NotFound = () => {
  const navigate = useNavigate();
  const { changeView } = useView();

  const onChangeView = useCallback((id: number | null): void => {
    if (id !== null) {
      changeView(id);
      navigate('/');
    }
  }, [changeView, navigate]);

  const goBack = useCallback(() => {
    navigate(-2);
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Header changeView={onChangeView} />
      <section className={styles.notfound}>
        <div className={styles.notfound__block}>
          <img src={NotFoundImg} className={styles.notfound__block_img} alt="" />
          <p className={styles.notfound__block_text}>
            The requested page does not exist.
          </p>
          <p className={styles.notfound__block_text}>
            You can back to previous page and try again
          </p>
          <button onClick={goBack} className={classNames(styles.notfound__block_btn, styles.btn)}>
            Back
          </button>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default NotFound;
