import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentState, convertFromRaw } from 'draft-js';
import api from '@services/api';
import { useView } from '@hooks';
import { Header, Footer } from '@components/main';
import { RichContent } from '@components/organisms';

import styles from './style.module.scss';

const Terms = () => {
  const navigate = useNavigate();
  const { changeView } = useView();

  const [content, setContent] = useState<ContentState | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const loadContent = useCallback(async () => {
    try {
      const { data } = await api.pages.getPage('landingTerms');

      if (data.content) {
        const raw = convertFromRaw(JSON.parse(data.content));
        setContent(raw);
      }

      setIsLoaded(true);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onChangeView = useCallback((id: number | null): void => {
    if (id !== null) {
      changeView(id);
      navigate('/');
    }
  }, [changeView, navigate]);

  useEffect(() => {
    loadContent();

    window.scrollTo(0, 0);
  }, [loadContent]);

  return (
    <Fragment>
      <Header changeView={onChangeView} />
      <div className={styles.main}>
        <div className={styles.privacy}>
          <div className={styles.privacy__content}>
            {isLoaded && content && (
              <RichContent content={content} />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Terms;
