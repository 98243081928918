interface propTypes {
  width?: number;
  height?: number;
  fill?: string;
}

const Pause = ({ width = 80, height = 80, fill = 'white' }: propTypes) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M186.11,52.39a18.8,18.8,0,0,0-18.8,18.79V440.82a18.8,18.8,0,0,0,37.6,0V71.18A18.8,18.8,0,0,0,186.11,52.39Z" />
    <path d="M325.89,52.39a18.8,18.8,0,0,0-18.8,18.79V440.82a18.8,18.8,0,0,0,37.6,0V71.18A18.8,18.8,0,0,0,325.89,52.39Z" />
  </svg>
);

export default Pause;
