interface props {
  fill?: string;
}

const SwipeDown = ({ fill = 'white' }: props) => (
  <svg width="60" height="41" viewBox="0 0 60 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M58.1543 13.5245L30.1543 35.8457L2.1543 13.5245M58.1543 1.8457L30.1543 24.1669L2.1543 1.8457"
      stroke={fill}
      strokeWidth="3"
    />
  </svg>
);

export default SwipeDown;
