import { Navigate, Route, Routes } from 'react-router-dom';

// DEFAULT PAGES
import Home from '@components/pages/public/Home';
import Privacy from '@components/pages/public/Privacy';
import FaqPage from '@components/pages/public/Faq';
import ArtRooms from '@components/pages/public/ArtRoom';
import TermsPage from '@components/pages/public/Terms';
import NotFound from '@components/pages/public/NotFound';

const AppRoutes = () => (
  <Routes>
    {/* PUBLIC PAGES */}
    <Route path="/" element={<Home />} />
    <Route path="/artroom" element={<ArtRooms />} />
    <Route path="/faq" element={<FaqPage />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/terms" element={<TermsPage />} />
    <Route path="/not-found-404" element={<NotFound />} />
    <Route path="*" element={<Navigate to="/not-found-404" />} />
  </Routes>
);

export default AppRoutes;
