import { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { SECOND_URL } from '@constants/environment';
import { BurgerIcon, MainLogo } from '@components/icons';

import styles from './style.module.scss';

interface IPropTypes {
  changeView: (arg0: number | null) => void;
  selectedView?: number | null;
}

const links: {
  title: string;
  url?: string;
  external?: boolean;
}[] = [
  { title: 'Art in space' },
  {
    title: 'Art Room',
    url: '/artroom',
  },
  { title: 'Artists' },
  { title: 'Programming' },
  {
    title: 'Market Place',
    url: SECOND_URL || 'https://stage.artinspace.art',
    external: true,
  },
];

const Header = ({ changeView }: IPropTypes) => {
  const navigate = useNavigate();

  const [isHideScroll, setIsHideScroll] = useState<boolean>(false);
  const [isHideMobile, setIsHideMobile] = useState<boolean>(true);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const prevScrollValue = useRef<number>(0);

  const listener = useCallback((): void => {
    if (headerRef.current?.offsetTop === window.pageYOffset) {
      const hide = window.scrollY > prevScrollValue.current;
      setIsHideScroll(hide);
      changeView(null);

      prevScrollValue.current = window.scrollY;

      if (isMobile) {
        setIsHideMobile(true);
      }
    }
  }, [changeView]);

  useEffect(() => {
    window.addEventListener('scroll', listener, { passive: true });
    return () => window.removeEventListener('scroll', listener);
  }, [listener]);

  return (
    <Fragment>
      {/* desktop */}
      <div
        className={classNames(styles.desktop_header, isHideScroll && styles.header_hidden)}
        ref={!isMobile ? headerRef : null}
      >
        <button className={styles.desktop_header__link} onClick={(): void => changeView(100)}>
          <MainLogo />
        </button>
        <nav className={styles.desktop_header__link_wrapper}>
          {links.map(({
            title,
            url,
            external,
          }, index) => (
            <button
              key={index}
              className={styles.desktop_header__link}
              onClick={() => {
                if (url) {
                  if (external) {
                    return window.location.href = url;
                  }

                  navigate(url);
                } else {
                  changeView(index);
                }
              }}
            >
              {title}
            </button>
          ))}
        </nav>
      </div>
      {/* mobile */}
      <div
        className={classNames(styles.mobile_header, isHideScroll && styles.header_hidden)}
        ref={isMobile ? headerRef : null}
      >
        <button className={styles.desktop_header__link} onClick={(): void => changeView(100)}>
          <MainLogo />
        </button>
        <button
          className={styles.mobile_header__toggler}
          onClick={() => setIsHideMobile(!isHideMobile)}
        >
          <BurgerIcon width={32} height={32} />
        </button>
        {isHideMobile ? null : (
          <div className={styles.mobile_header__link_wrapper}>
            <button
              className={styles.mobile_header__close_button}
              onClick={() => setIsHideMobile(true)}
            >
            </button>
            {links.map(({
              title,
              url,
              external,
            }, index) => (
              <button
                key={index}
                className={styles.mobile_header__link}
                onClick={() => {
                  if (url) {
                    if (external) {
                      return window.location.href = url;
                    }

                    navigate(url);
                  } else {
                    changeView(index);
                  }
                }}
              >
                {title}
              </button>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default memo(Header);
