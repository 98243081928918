import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { isMacOs, isIOS } from 'react-device-detect';
import { PauseIcon, PlayIcon } from '../../../../../icons';

import style from './style.module.scss';

interface propTypes {
  src: string;
  controller?: boolean;
  className?: string | boolean;
  autoPlay?: boolean;
  backgroundScreen?: string;
}

const Video = ({ src, className, controller = false, autoPlay = false, backgroundScreen = '' }: propTypes) => {
  const [isPlayed, setIsPlayed] = useState<boolean>(false);
  const playerRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!autoPlay) {
      (async () => {
        try {
          if (isPlayed) {
            await playerRef.current?.play();
          } else {
            playerRef.current?.pause();
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [isPlayed, autoPlay]);

  return (
    <div className={classNames(style.root, className)}>
      {(isMacOs || isIOS) ? null : (!controller && !autoPlay) && (
        <div className={style.button_wrapper}>
          <button
            className={classNames(style.button_wrapper__button, isPlayed && style.hidden_button)}
            onClick={() => setIsPlayed(!isPlayed)}
          >
            {isPlayed ? <PauseIcon /> : <PlayIcon />}
          </button>
        </div>
      )}
      <video
        ref={playerRef}
        className={style.video}
        preload="metadata"
        playsInline
        loop
        muted={(isMacOs || isIOS) ? true : autoPlay}
        controls={autoPlay ? false : (isMacOs || isIOS) ? true : controller}
        autoPlay={autoPlay}
        poster={backgroundScreen}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
