interface propTypes {
  fill?: string;
  className?: string;
}

const InShape = ({ fill = '#E1A16A', className }: propTypes) => (
  <svg width="90" height="70" viewBox="0 0 87 67" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_10_430)">
      <path d="M0.319336 66.6859V0.314087H15.7249V66.6859H0.319336Z" stroke={fill} strokeMiterlimit="10" />
      <path d="M71.2754 0.314087H86.7V66.6859H73.1396L49.3322 28.5626V66.6859H33.9268V0.314087H48.049L71.2754 37.8657V0.314087Z" stroke={fill} strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_10_430">
        <rect width="90" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default InShape;
