interface propTypes {
  width?: number;
  height?: number;
  fill?: string;
}

const PlayIcon = ({ width = 100, height = 100, fill = 'white' }: propTypes) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g><path d="M144,124.9L353.8,256L144,387.1V124.9 M128,96v320l256-160L128,96L128,96z" /></g>
  </svg>
);

export default PlayIcon;
