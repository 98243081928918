import { forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import Video from '../../Video';
import { ArtShape, RoomShape } from '../../../../../../icons';

import styles from './style.module.scss';
import animations from '@styles/animations.module.scss';

import Circle1 from '@assets/icons/circle_1.svg';
import Circle2 from '@assets/icons/circle_2.svg';
import Circle3 from '@assets/icons/circle_3.svg';
import VideoSrc from '@assets/videos/art-in-space.mp4';

interface propTypes {
  id: number;
  selectedView: Number | null;
}

const ExperienceSection = forwardRef<HTMLDivElement, propTypes>(({ id, selectedView }, forwardedRef) => {
  const { ref, inView } = useInView({});

  return (
    <section ref={selectedView === id ? forwardedRef : null} className={styles.section_wrapper}>
      <div ref={ref} className={classNames(styles.experience_section)}>
        <h2 className={classNames(styles.experience_section__name, inView && animations.from_bottom_to_top)}>
          Art in Room
        </h2>
        <div className={styles.experience_section__wrapper}>
          <div className={classNames(styles.experience_section__content, inView && animations.from_left_to_right)}>
            <h2 className={styles.experience_section__title}>
              about the art room
            </h2>
            <p className={styles.experience_section__description}>
              The Art room is a multisensory immersive area that stimulates
              and involves the 5 senses.The artists creates a work of art by
              combining video, audio, aromas, light effects, fog and temperature.
              Everything is automated by the centralized control system of the room or managed via Tablet.
              The space, if programmed, can be used for various multisensory artistic experiences.
            </p>
            <p className={styles.experience_section__sub_title}>
              This technology brigdes contemporary art and the digital world creating unique art experience.
            </p>
          </div>
          <Video
            className={classNames(styles.experience_section__video, inView && animations.from_bottom_to_top)}
            src={VideoSrc}
          />
        </div>
      </div>
      <div className={styles.section_animated_back}>
        <ArtShape
          className={classNames(styles.experience_section__shape_art, inView && animations.from_bottom_to_top)}
          fill="#712E19"
        />
        <RoomShape
          className={classNames(styles.experience_section__shape_room, inView && animations.from_bottom_to_top)}
          fill="#712E19"
        />
        <img
          src={Circle1}
          alt="shape"
          className={classNames(
            styles.experience_section__shape_1, inView && (animations.from_bottom_to_top, animations.shapes),
          )}
        />
        <img
          src={Circle2}
          alt="shape"
          className={classNames(
            styles.experience_section__shape_2, inView && (animations.from_bottom_to_top, animations.shapes),
          )}
        />
        <img
          src={Circle3}
          alt="shape"
          className={classNames(
            styles.experience_section__shape_3, inView && (animations.from_bottom_to_top, animations.shapes),
          )}
        />
      </div>
      <Video
        className={styles.experience_section__mobile_video}
        src={VideoSrc}
      />
    </section>
  );
});

export default ExperienceSection;
