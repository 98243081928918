import { useState } from 'react';
import classNames from 'classnames';

import RightIcon from '@assets/icons/right-arrow-icon.svg';
import DownIcon from '@assets/icons/bottom-icon.svg';
import styles from '../style.module.scss';

interface IPropTypes {
  question: string;
  answer: string;
}

const CollapseItem = ({
  question,
  answer,
}: IPropTypes) => {
  const [isHide, setIsHide] = useState<boolean>(true);

  return (
    <div className={classNames(styles.admin__faq_block_item, !isHide && styles.admin__faq_block_item_active)}>
      <div className={styles.admin__faq_block_question} onClick={() => setIsHide(!isHide)}>
        <p className={styles.admin__faq_block_question_text}>
          {question}
        </p>
        <button className={styles.admin__faq_block_item_button}>
          <img src={RightIcon} className={styles.admin__faq_block_item_closed} alt="" />
          <img src={DownIcon} className={styles.admin__faq_block_item_open} alt="" />
        </button>
      </div>
      {!isHide ? (
        <div className={styles.admin__faq_block_answer}>
          <p className={styles.admin__faq_block_answer_text}>
            {answer}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default CollapseItem;
