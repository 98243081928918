import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loading-icons';
import { useView } from '@hooks';
import api from '@services/api';
import { Header, Footer } from '@components/main';
import CollapseItem from './CollapseItem';

import styles from './style.module.scss';

interface IData {
  question: string;
  answer: string;
}

const Faq = () => {
  const navigate = useNavigate();
  const { changeView } = useView();

  const [data, setData] = useState<IData[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const onChangeView = useCallback((id: number | null): void => {
    if (id !== null) {
      changeView(id);
      navigate('/');
    }
  }, [changeView, navigate]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    let mounted = true;
    const fetchData = async () => {
      try {
        const { data } = await api.pages.getPage('faq');

        if (mounted) {
          setData(data.content);
          timer = setTimeout(() => {
            setLoaded(true);
          }, 1500);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
    return () => {
      mounted = false;
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={styles.main}>
      <Header changeView={onChangeView} />
      <section className={styles.admin}>
        {loaded ? (
          <div className={styles.admin__block}>
            <div className={styles.admin__main}>
              <div className={styles.admin__faq_block}>
                <h1 className={styles.admin__main_title}>
                  FREQUENTLY ASKED QUESTIONS (FAQ)
                </h1>
                {data?.map((data, index) => (
                  <CollapseItem key={index} {...data} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.loading_box}>
            <Bars height={100} fill="#712e19" />
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
};

export default Faq;
