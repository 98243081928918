import { useRef, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';

import { useClickOutside } from '@hooks';
import RightIcon from '@assets/icons/right-arrow-icon.svg';
import MapIcon from '@assets/icons/grey-map-icon.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../style.module.scss';

interface IPropTypes {
  title: string;
  description: string;
  location: string;
  photos: {
    thumb: string;
    full: string;
  }[];
}

const sliderSettings = {
  infinite: true,
  arrows: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Item = ({ title, description, location, photos }: IPropTypes) => {
  const sliderRef = useRef(null);
  const [fullImage, setFullImg] = useState<string | null>();
  const popover = useRef(null);
  const close = useCallback(() => setFullImg(null), []);
  useClickOutside(popover, close);
  return (
    <div className={styles.room__container}>
      <div className={styles.room__container_item}>
        <h2 className={styles.room__container_item_title}>{title}</h2>
        <p className={styles.room__container_item_text}>{description}</p>
        <p className={styles.room__container_item_room}>
          <img src={MapIcon} alt='' />
          {location}
        </p>
      </div>
      {photos.length ? (
        <div className={styles.room__slider_root}>
          {photos.length > 3 ? (
            <div className={styles.room__slider_root_arrows_box}>
              <button
                onClick={() => {
                  // fixme
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  sliderRef.current?.slickPrev();
                }}
              >
                <img alt='' src={RightIcon} />
              </button>
              <button
                onClick={() => {
                  // fixme
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  sliderRef.current?.slickNext();
                }}
              >
                <img alt='' src={RightIcon} />
              </button>
            </div>
          ) : null}
          <Slider ref={sliderRef} {...sliderSettings}>
            {photos?.map(({ thumb, full }, index) => (
              <>
                <div key={index} className={styles.room__slider_item}>
                  <img src={thumb} className={styles.room__slider_item_img} alt='' onClick={() => setFullImg(full)} />
                </div>
              </>
            ))}
          </Slider>
          {!isMobile && fullImage && (
            <div className={styles.modal}>
              <img src={fullImage} alt='' ref={popover} />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Item;
