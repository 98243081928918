interface propTypes {
  fill?: string;
  className?: string;
}

const SpaceShape = ({ fill = '#E1A16A', className }: propTypes) => (
  <svg width="320" height="72" viewBox="0 0 320 72" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_11_1238)">
      <path d="M0.317383 50.2606H16.1029C16.2172 54.7351 20.8843 58.0845 26.8595 58.0845C32.479 58.0845 36.6635 55.6811 36.6635 51.2258C36.6635 48.669 34.6316 46.0482 28.2945 44.1242L19.5699 41.4779C3.90497 37.2656 0.92058 28.0035 0.92058 21.5028C0.92058 8.98716 12.0327 0.31958 26.0212 0.31958C39.7685 0.31958 50.29 8.38631 50.29 21.9822H34.5109C34.5109 17.7698 31.6408 14.2798 25.7863 14.2798C20.5223 14.2798 16.8141 17.2905 16.8141 21.017C16.8141 22.4616 17.449 25.5937 24.3513 27.5177L31.971 29.6846C42.2576 32.6953 52.4173 37.6299 52.4173 50.5035C52.4173 65.4289 39.3875 71.6803 26.2371 71.6803C10.8389 71.6803 0.317383 63.1406 0.317383 50.2606Z" stroke={fill} strokeMiterlimit="10" />
      <path d="M96.5484 1.28479C113.058 1.28479 120.455 12.8352 120.455 24.3921C120.455 35.9489 113.045 47.2564 96.5484 47.2564H82.8011V70.843H67.022V1.28479H96.5484ZM95.8309 33.424C102.181 33.424 104.676 28.8473 104.676 24.3984C104.676 19.9496 102.136 15.2514 95.9515 15.2514H82.8011V33.424H95.8309Z" stroke={fill} strokeMiterlimit="10" />
      <path d="M174.13 70.843L168.631 54.5945H142.813L137.307 70.843H120.455L147.124 1.28479H164.338L190.893 70.843H174.13ZM147.353 40.8771H163.862L155.607 16.5873L147.353 40.8771Z" stroke={fill} strokeMiterlimit="10" />
      <path d="M194.805 35.9425C194.805 16.4468 211.066 0.441096 231.392 0.441096C237.216 0.422836 242.958 1.81249 248.137 4.4932C253.316 7.17391 257.78 11.0673 261.153 15.8459L249.089 24.5071C245.146 18.8566 239.526 15.4816 231.995 15.4816C219.562 15.4816 210.717 24.5071 210.717 35.9361C210.717 47.3651 219.321 56.6335 231.875 56.6335C239.494 56.6335 245.146 53.2649 249.089 47.608L261.153 56.2756C257.779 61.0523 253.314 64.9439 248.136 67.6234C242.957 70.3028 237.215 71.692 231.392 71.6741C211.066 71.6804 194.805 55.6811 194.805 35.9425Z" stroke={fill} strokeMiterlimit="10" />
      <path d="M289.721 57.0043H320.683V70.843H273.942V1.28479H319.73V15.245H289.721V29.5888H316.022V43.6513H289.721V57.0043Z" stroke={fill} strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_11_1238">
        <rect width="320" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SpaceShape;
