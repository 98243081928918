import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { combineProviders } from 'react-combine-providers';
import {
  ViewProvider,
} from '@providers';
import App from './App';

import './styles/main.scss';

const providers = [
  ViewProvider,
];

const combinedProviders = combineProviders();
providers.forEach(provider => combinedProviders.push(provider));
const MasterProvider = combinedProviders.master();

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
  <MasterProvider>
    <Router>
      <App />
    </Router>
  </MasterProvider>,
);
