import { useCallback, useEffect, useRef } from 'react';
import { useView } from '@hooks';
import { Header, Footer } from '@components/main';
import ArtInSpace from './components/landingPageSections/ArtInSpace';
import DiscoverSection from './components/landingPageSections/DiscoverSection';
import ArtInRoom from './components/landingPageSections/ArtInRoom';
//import JoinSection from './components/landingPageSections/JoinSection';
import ProgrammingSection from './components/landingPageSections/ProgrammingSection';
//import EventsSection from './components/landingPageSections/Events';

import styles from './style.module.scss';

const Homepage = () => {
  const viewRef = useRef<HTMLDivElement | null>(null);
  const isMobile = window.innerWidth < 768;
  const {
    view,
    changeView
  } = useView();

  const onChangeView = useCallback((id: number | null): void => {
    changeView(id);
  }, [changeView]);

  useEffect(() => {
    if (viewRef.current) {
      window.scrollTo({
        top: viewRef.current.offsetTop - (isMobile ? 48 : 70),
        behavior: 'smooth',
      });
    }
  }, [view, isMobile]);

  return (
    <div className={styles.root}>
      <DiscoverSection id={100} selectedView={view} ref={viewRef} />
      <div className={styles.main}>
        <Header changeView={onChangeView} />
        <ArtInSpace id={0} selectedView={view} ref={viewRef} />
        <ArtInRoom id={1} selectedView={view} ref={viewRef} />
        {/* <EventsSection id={3} selectedView={view} ref={viewRef} /> */}
        <ProgrammingSection id={3} selectedView={view} ref={viewRef} />
      {/*  <JoinSection id={2} selectedView={view} ref={viewRef} />*/}
        <Footer />
      </div>
    </div>
  );
};

export default Homepage;
